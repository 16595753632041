<template>
  <div>
    <form @submit.prevent="toggleModal">
      <loading-button :is-loading="false"> Update Product </loading-button>
    </form>
    <modal
      v-if="productCopy"
      :open="modalVisible"
      :on-close="toggleModal"
      size="sm"
      sticky-footer
      id="update-product"
      modal-title="Update product"
      ><div class="space-y-8">
        <div class="space-y-3">
          <key-value label="Name" class="col-span-full">
            <input type="text" class="w-full" v-model="productCopy.name" />
          </key-value>
          <key-value label="Short Name" class="col-span-full">
            <input type="text" class="w-full" v-model="productCopy.shortname" />
          </key-value>
          <key-value label="SKU" v-if="!isFoodProduct" class="col-span-full">
            <input type="text" class="w-full" v-model="productCopy.sku" />
          </key-value>
          <key-value label="">
            <radio-buttons
              :options="typeCategorys"
              v-model="productCopy.foodcomplete"
            />
          </key-value>
          <!-- <key-value label="Type">
            <radio-buttons
              :options="foodTypes"
              v-model="productCopy.foodtype"
            />
          </key-value>
          <key-value label="Category">
            <radio-buttons
              :options="foodCategorys"
              v-model="productCopy.foodcategory"
            />
          </key-value> -->
          <key-value
            label="Weight"
            v-if="productCopy.foodtype !== 'dry' && isFoodProduct"
          >
            <div class="flex space-x-2 items-center">
              <div class="w-20">
                <input type="text" v-model="productCopy.size" />
              </div>
              <div class="text-gray-400 text-sm">g</div>
            </div>
          </key-value>

          <!-- <div class="py-2" v-if="isFoodProduct"><hr /></div>
          <key-value label="Price" v-if="isFoodProduct" class="col-span-full">
            <input
              type="text"
              class="w-full"
              v-model.number="productCopy.exvat"
            />
          </key-value> -->
          <div class="py-2"><hr /></div>
          <div class="font-semibold mb-2 text-left">Lead Times</div>
          <key-value label="PO" class="col-span-full">
            <div class="flex space-x-2 items-center">
              <div class="w-20">
                <input type="text" v-model="productCopy.leadtime" />
              </div>
              <div class="text-gray-400 text-sm">weeks</div>
            </div>
          </key-value>
          <key-value label="Delivery" class="col-span-full">
            <div class="flex space-x-2 items-center">
              <div class="w-20">
                <input type="text" v-model="productCopy.etdtime" />
              </div>
              <div class="text-gray-400 text-sm">weeks</div>
            </div>
          </key-value>
          <div class="py-2"><hr /></div>
          <div class="font-semibold mb-2 text-left">Details</div>
          <key-value
            label="Strapline"
            v-if="isFoodProduct"
            items-start
            class="col-span-full"
          >
            <textarea
              class="w-full"
              type="text"
              v-model="productCopy.strapline"
            ></textarea>
          </key-value>
          <key-value label="Description" items-start class="col-span-full">
            <textarea
              class="w-full"
              type="text"
              v-model="productCopy.description"
            ></textarea>
          </key-value>
          <key-value
            label="Composition"
            v-if="isFoodProduct"
            items-start
            class="col-span-full"
          >
            <textarea
              class="w-full"
              type="text"
              v-model="productCopy.composition"
            ></textarea>
          </key-value>
          <key-value
            label="Nutritional Additives"
            v-if="isFoodProduct"
            items-start
            class="col-span-full"
          >
            <textarea
              class="w-full"
              type="text"
              v-model="productCopy.additives"
            ></textarea>
          </key-value>
          <key-value
            label="Analytical Constituents"
            v-if="isFoodProduct"
            items-start
            class="col-span-full"
          >
            <textarea
              class="w-full"
              type="text"
              v-model="productCopy.constituents"
            ></textarea>
          </key-value>
        </div>
        <div
          class="flex space-x-4 sticky bottom-0 left-0 right-0 py-6 w-full bg-white"
        >
          <form @submit.prevent="addProduct">
            <loading-button
              primary
              :is-enabled="true"
              :is-loading="isLoading"
              label="Update Product"
            />
          </form>
          <form @submit.prevent="toggleModal('cancel')">
            <loading-button label="Cancel" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
// import DropdownButton from '@/components/common/ui/DropdownButton';
// import RadioButtons from '@/components/common/ui/RadioButtons';
import { mapGetters } from 'vuex';
import { cloneDeep, omit, includes } from 'lodash/fp';
import KeyValue from '@/components/common/ui/KeyValue.vue';
import RadioButtons from '../RadioButtons.vue';
import { create } from 'lodash';

export default {
  components: {
    Modal,
    LoadingButton,
    // DropdownButton,
    // RadioButtons,
    KeyValue,
    RadioButtons
  },
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      modalVisible: false,
      productCopy: null,
      foodTypes: [
        {
          value: 'wet_pouch',
          label: 'Pouch'
        },
        {
          value: 'wet_tasty',
          label: 'Tasty Slices'
        },
        {
          value: 'wet_chunk',
          label: 'Tasty Chunks'
        },
        {
          value: 'wet_tray',
          label: 'Tray'
        },
        {
          value: 'wet_can',
          label: 'Can'
        },
        {
          value: 'wet_smooth',
          label: 'Smooth'
        },
        {
          value: 'dry',
          label: 'Dry'
        }
      ],
      foodCategorys: [
        {
          value: 'kitten',
          label: 'Kitten'
        },
        {
          value: 'adult',
          label: 'Adult'
        }
      ],
      typeCategorys: [
        {
          value: true,
          label: 'Complete'
        },
        {
          value: false,
          label: 'Complementary'
        }
      ]
    };
  },

  computed: {
    ...mapGetters({
      products: 'getProducts'
    }),
    isFoodProduct() {
      return !includes(this.product.foodtype, ['srp', 'box', 'tape']);
    },

    increment() {
      return this.productCopy.foodtype === 'dry' ? 800 : 5;
    }
  },
  methods: {
    toggleModal() {
      this.modalVisible = !this.modalVisible;
      if (this.modalVisible) {
        this.createCopy(this.product);
        this.productCopy.leadtime = this.productCopy.leadtime / 7;
        this.productCopy.etdtime = this.productCopy.etdtime / 7;
      }
    },

    addProduct() {
      this.isLoading = true;

      const data = {
        ...this.productCopy,
        increments: this.increment,
        sortorder: 1000,
        foodcomplete: this.productCopy.foodcomplete.value
      };

      data.leadtime = data.leadtime * 7;
      data.etdtime = data.etdtime * 7;

      if (!this.isFoodProduct) {
        data.strapline = '';
        data.composition = '';
        data.constituents = '';
        data.additives = '';
        data.image = '';
        data.size = '';
      }

      // if data.size is null, set it to empty string
      if (!data.size) {
        data.size = '';
      }
      this.$store
        .dispatch('updateProduct', {
          id: data.id,
          data: omit(
            [
              'barcodeid',
              'hidden',
              'popular',
              'feeding',
              'discontinued',
              'available',
              'id',
              'displayType',
              'insubs',
              'instock'
            ],
            data
          )
        })
        .then(response => {
          this.$store
            .dispatch('getProductProfile', {
              id: Number(response.data.content[0].id)
            })
            .then(() => {
              this.toggleModal();
            })
            .catch(err => {
              console.log(err);
            });
          this.isLoading = false;
        })
        .catch(error => {
          console.log(error);
          this.isLoading = false;
        });
    },
    createCopy(val) {
      const product = { ...val };

      const foodcomplete = this.typeCategorys.find(
        type => type.value === product.foodcomplete
      );

      product.foodcomplete = foodcomplete;
      this.productCopy = product;
    }
  },

  mounted() {
    // create a clone of the product using es6 spread operator
    this.createCopy(this.product);
  }
};
</script>

<style></style>
