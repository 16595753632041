<template>
  <div class="px-8">
    <div class="space-y-4">
      <div class="text-xl font-semibold py-5">
        Stock Predictions
      </div>
      <div
        class="flex items-center space-x-8 overflow-x-scroll flex-nowrap group "
      >
        <!-- <stock-month
          v-for="(month, index) in stockMonths"
          :key="`month-${index}`"
          :month="month"
          :index="index"
        /> -->
        <stock-timeline />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import StockTimeline from '@/components/common/ui/stock/StockTimeline.vue';

export default {
  components: { StockTimeline },
  computed: {
    ...mapGetters({
      predictions: 'getCurrentPredictions'
    })
  },
  created() {
    this.$store.dispatch('getStockPredictions').then(() => {});
  }
};
</script>

<style lang="css" scoped></style>
