<template>
  <div
    v-if="stockStat"
    class="flex items-center text-sm text-gray-400 space-x-2 flex-none"
  >
    <div class="w-16 text-right">
      {{ formatValue(Number(stockValue), this.product.foodtype) }}
    </div>
    <div
      class="w-48  rounded-full overflow-hidden relative h-2-prog flex-none"
      :class="`bg-${stockStyle}-100`"
    >
      <div
        class=" absolute left-0 h-full rounded-full "
        :class="`bg-${stockStyle}-400`"
        :style="styleObj"
      ></div>
    </div>
    <div class="w-16 flex-none text-left">
      {{ formatValue(stockStat.lastStockCount, this.product.foodtype) }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { toArray, find, isNaN } from 'lodash/fp';
import utils from '@/utils';
export default {
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      predictions: 'getCurrentPredictions',
      stockStats: 'getCurrentStockStats'
    }),
    stockStat() {
      return find(['sku', this.product.sku], this.stockStats);
    },
    stockValue() {
      return this.stockStat.instock <= 0 ? 0 : this.stockStat.instock;
    },
    monthsRemaining() {
      let remaining = utils.round(this.stockValue / this.stockStat.active, 0.5);

      return (remaining = remaining || 0);
    },
    stockStyle() {
      if (this.monthsRemaining <= 2) {
        return 'red';
      }
      if (this.monthsRemaining <= 4) {
        return 'yellow';
      }

      return 'green';
    },

    stockPercentage() {
      return utils
        .getPercentageOf(this.stockStat.lastStockCount, this.stockStat.instock)
        .toFixed();
    },
    styleObj() {
      return {
        width: `${utils.getPercentageOf(
          this.stockStat.lastStockCount,
          this.stockStat.instock
        )}%`
      };
    },
    flattenedStock() {
      return toArray(this.predictions.products);
    },
    productStock() {
      return find(['sku', this.product.sku], this.flattenedStock);
    }
  },
  methods: {
    formatValue(value, type) {
      if (isNaN(value)) {
        return '-';
      } else {
        if (type === 'dry') {
          const val = value / this.product.increments;

          return val.toLocaleString();
        } else if (type === 'tape') {
          const tape = { C_TAPE_BRAND: 100, C_TAPE_CLEAR: 66 };
          const val = value / tape[this.product.sku];
          return utils.round(val, 0).toLocaleString();
        } else {
          return value.toLocaleString();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.h-2-prog {
  height: 7px;
}
</style>
