<template>
  <div>
    <div
      class="hover:underline text-red-500 cursor-pointer flex space-x-1"
      @click.prevent="toggleModal"
    >
      <div>Discontinue</div>
      <div>&rarr;</div>
    </div>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      size="xs"
      id="discontinue-product"
      :modal-title="`Discontinue product`"
      ><div class="space-y-4">
        <div class="space-y-4">
          <div>
            You are about to discontinue
            <span class="font-medium">{{ product.name }}</span
            >. This will remove it from the website and will make it unavailable
            to customers.
          </div>
          <div>Are you sure?</div>
        </div>
        <div class="flex space-x-4">
          <form @submit.prevent="discontinueProduct" class="space-y-4">
            <loading-button :is-loading="isLoading" warning>
              Yes, discontinue product</loading-button
            >
          </form>
          <form @submit.prevent="toggleModal">
            <loading-button label="Cancel" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import { mapGetters } from 'vuex';
import utils from '@/utils';
export default {
  components: {
    Modal,
    LoadingButton
  },
  data() {
    return {
      isLoading: false
    };
  },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      modalVisible: 'getModalVisible'
    })
  },
  methods: {
    toggleModal() {
      utils.toggleModal();
    },
    discontinueProduct() {
      this.isLoading = true;
      this.$store
        .dispatch('discontinueProduct', {
          id: this.$route.params.id
        })
        .then(() => {
          this.$store
            .dispatch('getProduct', { id: Number(this.$route.params.id) })
            .then(() => {
              this.isLoading = false;
              this.toggleModal();
            })
            .catch(() => {
              this.isLoading = false;
            });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>

<style></style>
