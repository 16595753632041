<template>
  <div class="space-y-4">
    <div class="flex  items-center">
      <div class="text-gray-500 w-48">Is Hidden?</div>
      <div>
        <switch-toggle v-model="product.hidden" keyId="hidden-toggle" />
      </div>
    </div>
    <div class="flex  items-center">
      <div class="text-gray-500 w-48">Is Available?</div>
      <div>
        <switch-toggle v-model="product.available" keyId="available-toggle" />
      </div>
    </div>
    <div class="flex  items-center">
      <div class="text-gray-500 w-48">Is popular?</div>
      <div>
        <switch-toggle v-model="product.popular" keyId="popular-toggle" />
      </div>
    </div>
  </div>
</template>

<script>
import SwitchToggle from '@/components/common/ui/SwitchToggle';

export default {
  name: 'product-settings',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  components: { SwitchToggle },
  watch: {
    'product.available': {
      handler(val) {
        this.updateProduct('available', val);
      }
    },
    'product.hidden': {
      handler(val) {
        this.updateProduct('hidden', val);
      }
    },
    'product.popular': {
      handler(val) {
        this.updateProduct('popular', val);
      }
    }
  },
  methods: {
    updateProduct(type, val) {
      this.$store.dispatch('updateProduct', {
        type: type,
        id: this.$route.params.id,
        data: {
          [type]: val
        }
      });
    }
  }
};
</script>

<style></style>
