var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex space-x-2 flex-none",class:{ 'w-full': _vm.isFull }},_vm._l((_vm.options),function(option,index){return _c('label',{key:`option-${index}`,staticClass:"py-1 px-3 rounded flex-none",class:[
      _vm.val === option.value || _vm.val === option
        ? 'bg-gray-600 text-white'
        : 'bg-gray-100 text-gray-400 hover:bg-gray-200 cursor-pointer',
      _vm.isFull ? 'flex-grow text-center' : '',
      _vm.disabled ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'
    ]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.val),expression:"val"}],attrs:{"type":"radio","id":`option-${index}`},domProps:{"value":option.value ? option.value : option,"checked":_vm._q(_vm.val,option.value ? option.value : option)},on:{"change":[function($event){_vm.val=option.value ? option.value : option},_vm.handleInput]}}),(option.icon)?_c('div',{staticClass:"space-x-1"},_vm._l((option.icon),function(icon,index){return _c('font-awesome-icon',{key:`icon-${index}`,staticClass:"text-xl",attrs:{"icon":['fal', icon]}})}),1):_vm._e(),(option.label)?_c('div',[_vm._v(_vm._s(option.label))]):_c('div',[_vm._v(_vm._s(_vm._f("startCase")(option)))])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }