<template>
  <div>
    <div>
      <key-value
        v-if="!isDry"
        label="Stock Count"
        :value="stockProduct.instock | numberFormat"
      />
      <key-value
        v-else
        label="Stock Count"
        :value="stockProduct.instock | formatKg"
      />
    </div>

    <key-value
      label="Days of stock"
      :value="stockProduct.daysRemaining | numberFormat"
    />
    <key-value
      label="Last updated"
      :value="stockProduct.lastStockCheck | formatDate('DD MMMM, YYYY hh:mm A')"
    />
    <!-- <div class="mt-6">
      <add-stock-button :stock="stockProduct" />
    </div> -->
  </div>
</template>

<script>
import KeyValue from '@/components/common/ui/KeyValue';
// import AddStockButton from '@/components/common/ui/stock/AddStockButton';
import { mapGetters } from 'vuex';
import { find } from 'lodash/fp';
export default {
  props: {
    product: {
      type: Object
    }
  },
  components: {
    // AddStockButton,
    KeyValue
  },
  computed: {
    ...mapGetters({
      predictions: 'getCurrentPredictions'
    }),
    stockProduct() {
      return find(['sku', this.product.sku], this.predictions.products);
    },
    isDry() {
      if (this.stockProduct) {
        return this.stockProduct.foodtype === 'dry';
      }
      return false;
    }
  },
  created() {
    this.$store.dispatch('getStockPredictions');
  }
};
</script>

<style lang="css" scoped></style>
