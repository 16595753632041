<template>
  <div class="flex space-x-2 flex-none" :class="{ 'w-full': isFull }">
    <label
      class="py-1 px-3 rounded flex-none"
      :class="[
        val === option.value || val === option
          ? 'bg-gray-600 text-white'
          : 'bg-gray-100 text-gray-400 hover:bg-gray-200 cursor-pointer',
        isFull ? 'flex-grow text-center' : '',
        disabled ? 'pointer-events-none cursor-not-allowed' : 'cursor-pointer'
      ]"
      :key="`option-${index}`"
      v-for="(option, index) in options"
    >
      <input
        type="radio"
        :id="`option-${index}`"
        :value="option.value ? option.value : option"
        v-model="val"
        @change="handleInput"
      />
      <div v-if="option.icon" class="space-x-1">
        <font-awesome-icon
          v-for="(icon, index) in option.icon"
          :key="`icon-${index}`"
          :icon="['fal', icon]"
          class="text-xl"
        />
      </div>
      <div v-if="option.label">{{ option.label }}</div>
      <div v-else>{{ option | startCase }}</div>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: [String, Number, Object] },
    isFull: { type: Boolean, default: false },
    options: { type: Array },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      val: this.value
    };
  },
  watch: {
    value(newVal) {
      this.val = newVal;
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.val);
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  > input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
}
</style>
