<template>
  <div>
    <form @submit.prevent="toggleModal">
      <loading-button :is-loading="false" secondary>
        Update stock...
      </loading-button>
    </form>
    <modal
      :open="modalVisible"
      :on-close="toggleModal"
      size="md"
      id="product-stock-update"
      modal-title="Update stock"
      ><div class="space-y-6">
        <div class="flex items-center space-x-2">
          <div>I want to</div>
          <radio-buttons
            :options="['remove', 'add']"
            v-model="stockUpdateType"
          />
          <div>stock</div>
        </div>
        <div>
          <div class="space-y-2 mb-4">
            <div
              v-for="(stock, index) in stockArr"
              :key="`stock-${index}`"
              class="flex space-x-4 items-center"
            >
              <dropdown-button is-full is-input class="flex-grow">
                <template v-slot:button>
                  <div v-if="stock.product" class="flex space-x-4 items-center">
                    <div class="space-x-1 flex items-center flex-grow">
                      <div>{{ stock.product.name }}</div>
                      <div v-if="stock.product.foodcategory === 'kitten'">
                        (Kitten)
                      </div>
                    </div>
                    <div class="text-gray-400 text-xs">
                      ({{ stock.product.sku }})
                    </div>
                  </div>
                  <div v-else class="text-gray-300">Choose a product...</div>
                </template>
                <template v-slot:search>
                  <div class="px-4 pt-4 pb-0">
                    <input
                      type="text"
                      autofocus
                      class="w-full"
                      placeholder="Search for a product"
                      ref="searchInput"
                      v-model="searchTerm"
                    />
                  </div>
                </template>

                <div class="py-2 border-t mt-4 max-h-96 overflow-y-auto">
                  <div class="space-y-4 divide-y" v-if="hasResults">
                    <div
                      v-for="(value, key) in groupedProducts"
                      :key="`cat-${key}`"
                    >
                      <div class="font-semibold mb-2 px-4 mt-4">
                        {{ key }}
                      </div>
                      <div>
                        <div
                          v-for="product in orderProducts(value)"
                          :key="`packer-${product.sku}`"
                          @click="setProduct(stock, product)"
                          class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                        >
                          <div class="space-x-1 flex items-center flex-grow">
                            <div>{{ product.name }}</div>
                            <div v-if="product.foodcategory === 'kitten'">
                              (Kitten)
                            </div>
                          </div>
                          <div class="text-gray-400 italic text-xs">
                            {{ product.sku }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    v-else
                    class="text-center text-gray-400 h-24 justify-center flex items-center"
                  >
                    No matching products
                  </div>
                </div>
              </dropdown-button>
              <div class="flex-none flex space-x-2">
                <dropdown-button is-input v-if="!isAdd" class="flex-none w-48">
                  <template v-slot:button>
                    <div>{{ findLabel(stock.type) }}</div>
                  </template>
                  <div class="py-2">
                    <div
                      v-for="typeIs in types"
                      :key="`type-${typeIs.value}`"
                      @click="stock.type = typeIs.value"
                      class="py-2 px-4 flex items-center space-x-3 hover:bg-gray-100 cursor-pointer"
                    >
                      <div class="flex-grow truncate">
                        {{ typeIs.label }}
                      </div>
                    </div>
                  </div>
                </dropdown-button>
                <div class="text-sm text-gray-400 flex-none flex-grow w-32">
                  <div class="flex items-center">
                    <div>Current</div>

                    <div class="flex-grow text-right" v-if="stock.product">
                      {{
                        getStat(
                          stock.product,
                          'instock',
                          stock.product.foodtype
                        ) | numberFormat
                      }}
                    </div>
                    <div class="flex-grow text-right" v-else>-</div>
                  </div>
                  <div class="flex items-center">
                    <div>After</div>
                    <div class="flex-grow text-right" v-if="stock.product">
                      {{
                        getTotal([
                          getStat(
                            stock.product,
                            'instock',
                            stock.product.foodtype
                          ),
                          stock.amount
                        ]) | numberFormat
                      }}
                    </div>
                    <div class="flex-grow text-right" v-else>-</div>
                  </div>
                </div>
              </div>
              <div class="w-20 flex-none">
                <input type="text" class="text-center" v-model="stock.amount" />
              </div>
            </div>
          </div>
          <div @click="addItem" class="text-gray-400">+ Add another...</div>
        </div>
        <div class="flex space-x-4">
          <form @submit.prevent="updateStock">
            <loading-button
              primary
              :is-enabled="true"
              :is-loading="isLoading"
              :label="`${isAdd ? 'Add stock' : 'Remove stock'}`"
            />
          </form>
          <form @submit.prevent="toggleModal('cancel')">
            <loading-button label="Cancel" secondary />
          </form>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/common/ui/Modal';
import LoadingButton from '@/components/common/ui/LoadingButton';
import DropdownButton from '@/components/common/ui/DropdownButton';
import RadioButtons from '@/components/common/ui/RadioButtons';
import { mapGetters } from 'vuex';
import { filter, includes, orderBy, isEmpty, sum } from 'lodash/fp';
import utils from '@/utils';
export default {
  components: {
    Modal,
    LoadingButton,
    DropdownButton,
    RadioButtons
  },
  data() {
    return {
      isLoading: false,
      stockArr: [],
      dryIncrement: 800,
      searchTerm: '',
      stockUpdateType: 'remove',
      types: [
        {
          value: 'waste',
          label: 'Damaged / Unusable'
        },
        {
          value: 'charity',
          label: 'Charity'
        },
        {
          value: 'other',
          label: 'Other'
        }
      ]
    };
  },
  props: {
    product: {
      type: Object
    }
  },
  computed: {
    ...mapGetters({
      modalVisible: 'getModalVisible',
      products: 'getProducts',
      stockStats: 'getCurrentStockStats'
    }),
    filteredProducts() {
      let products = this.products;
      const self = this;
      products = filter(function (o) {
        return !o.discontinued;
      }, products);
      if (this.searchTerm) {
        products = filter(function (o) {
          return includes(self.searchTerm.toLowerCase(), o.name.toLowerCase());
        }, products);
      }
      // return filter(function(o) {
      //   return !includes('taster', o.foodtype);
      // }, products);

      return products;
    },
    groupedProducts() {
      return utils.groupProducts(this.filteredProducts);
    },
    hasResults() {
      return !isEmpty(this.groupedProducts);
    },
    isAdd() {
      return this.stockUpdateType === 'add';
    }
  },
  methods: {
    toggleModal() {
      utils.toggleModal();

      this.stockArr = [
        {
          type: 'waste',
          amount: 0,
          product: null
        }
      ];
      this.stockUpdateType = 'remove';
    },
    getStat(product, key, type) {
      const val = Number(this.stockStats.find(s => s.sku === product.sku)[key]);
      return type === 'dry' ? val / this.dryIncrement : val;
    },
    getTotal(values) {
      const vals = [];
      values.forEach(v => {
        vals.push(parseInt(v));
      });
      if (this.isAdd) {
        return sum(vals);
      } else {
        return vals[0] - vals[1];
      }
    },
    setProduct(stock, product) {
      stock.product = product;
      this.searchTerm = '';
    },
    addItem() {
      this.stockArr.push({
        type: 'waste',
        amount: 0,
        product: null
      });
    },
    totalStock(newVal, current, type) {
      const isDry = type === 'dry';
      const val = isDry ? newVal * this.dryIncrement : newVal;
      return (val ? Number(val) : 0) + Number(current);
    },
    orderProducts(value) {
      return orderBy(['foodcategory', 'name'], ['asc', 'asc'], value);
    },
    findLabel(value) {
      return this.types.find(t => t.value === value).label;
    },
    updateStock() {
      this.isLoading = true;
      const promises = [];
      const validArr = this.stockArr.filter(sa => sa.product && sa.amount);
      validArr.forEach(s => {
        if (this.stockUpdateType === 'add') {
          promises.push(
            this.$store.dispatch('updateStockLevel', {
              id: s.product.id,
              stockLevel: this.totalStock(
                s.amount,
                this.getStat(s.product, 'instock'),
                s.product.foodtype
              )
            })
          );
        } else {
          promises.push(
            this.$store.dispatch('removeFood', {
              type: s.type,
              data: {
                sku: s.product.sku,
                quantity:
                  s.product.foodtype === 'dry'
                    ? parseInt(s.amount) * s.product.increments
                    : parseInt(s.amount),
                type: s.type
              }
            })
          );
        }
      });

      Promise.allSettled(promises)
        .then(() => {
          this.isLoading = false;
          this.$store.dispatch('getStockStats').then(() => {
            console.log('is all done');
            // this.$store.dispatch('getCharityStats');
            // this.$store.dispatch('getWastageStats');
            this.toggleModal();
          });
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  },
  mounted() {
    this.stockArr.push({
      type: 'waste',
      amount: 0,
      product: null
    });
  }
};
</script>

<style></style>
