<template>
  <div class="flex divide-x">
    <div class="w-64 py-2 px-4">
      <div v-if="dates.po" class="flex ">
        <div class="flex-grow">{{ dates.po | formatDate('D MMM, YYYY') }}</div>
        <div class="text-gray-500">({{ getShort(dates.po) }})</div>
      </div>
      <div v-else class="text-gray-400">-</div>
    </div>
    <div class="w-64 py-2 px-4">
      <div v-if="dates.delivery" class="flex ">
        <div class="flex-grow">
          {{ dates.delivery | formatDate('D MMM, YYYY') }}
        </div>
        <div class="text-gray-500">({{ getShort(dates.delivery) }})</div>
      </div>
      <div v-else class="text-gray-400">-</div>
    </div>
    <div class="w-64 py-2 px-4">
      <div v-if="dates.roDate" class="flex ">
        <div class="flex-grow">
          {{ dates.roDate | formatDate('D MMM, YYYY') }}
        </div>
        <div class="text-gray-500">({{ getShort(dates.roDate) }})</div>
      </div>
      <div v-else class="text-gray-400">-</div>
    </div>
  </div>
</template>

<script>
import { formatDistanceStrict } from 'date-fns';

export default {
  name: 'StockTimelineItem',
  props: {
    dates: {
      type: Object
    }
  },
  methods: {
    getShort(date) {
      return formatDistanceStrict(date, new Date());
    }
  }
};
</script>

<style></style>
