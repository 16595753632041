<template>
  <div class="px-8">
    <div class="space-y-4">
      <div class="flex items-center space-x-4">
        <div class="text-xl flex-grow font-semibold py-5">Products</div>
        <div class="flex items-center space-x-2">
          <div class="text-gray-500">Show discontinued</div>
          <switch-toggle v-model="showDiscontinued" />
        </div>
        <div v-if="isAdmin"><update-stock /></div>
        <div v-if="isAdmin"><add-product /></div>
      </div>

      <div>
        <tabs class="-mx-8" :padding="false">
          <tab
            key="Food"
            slot="food"
            :selected="true"
            :count="groupedFood.count"
          >
            <div class="block w-full h-full">
              <stock-list
                class="space-y-6 w-full overflow-x-auto"
                :data="groupedFood.data"
                show-image
              />
            </div>
          </tab>
          <tab
            key="Consumables"
            slot="consumables"
            :count="groupedConsumables.count"
          >
            <stock-list
              class="space-y-6 w-full overflow-x-auto"
              :data="groupedConsumables.data"
              :order="[['name'], ['asc']]"
              is-consumable
            />
          </tab>
        </tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { filter, includes } from 'lodash/fp';
import SwitchToggle from '@/components/common/ui/SwitchToggle';

import UpdateStock from '@/components/common/ui/products/UpdateStock';
import AddProduct from '@/components/common/ui/products/AddProduct';
import utils from '@/utils';
import Tabs from '../../components/common/ui/tabs/Tabs.vue';
import Tab from '../../components/common/ui/tabs/Tab.vue';
import StockList from '../../components/common/ui/stock/StockList.vue';
export default {
  data() {
    return {
      showDiscontinued: false,
      consumables: ['tape', 'box', 'box_insert', 'treat', 'taster']
    };
  },
  components: {
    SwitchToggle,
    UpdateStock,
    AddProduct,
    Tabs,
    Tab,
    StockList
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      stockStats: 'getCurrentStockStats',
      isAdmin: 'isAdmin'
    }),
    filteredProducts() {
      let products = this.products;
      if (!this.showDiscontinued) {
        products = filter(function (o) {
          return !o.discontinued;
        }, products);
      }
      return products;
    },
    groupedFood() {
      const vals = this.filteredProducts.filter(
        fp => !includes(fp.foodtype, this.consumables)
      );

      return { count: vals.length, data: utils.groupProducts(vals) };
    },
    groupedConsumables() {
      const vals = this.filteredProducts.filter(fp =>
        includes(fp.foodtype, this.consumables)
      );
      console.log('consumables', vals);
      const foodTypes = Array.from(new Set(vals.map(fp => fp.foodtype)));
      console.log('food types', foodTypes);

      return { count: vals.length, data: utils.groupProducts(vals) };
    }
  },
  methods: {},
  created() {
    if (!this.stockStats) {
      this.$store.dispatch('getStockStats');
    }
  }
};
</script>
