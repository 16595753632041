<template>
  <div class="space-y-8">
    <div :key="key" v-for="(value, key) in groupedFood.data">
      <div class="font-semibold  mb-2">
        {{ key }}
      </div>
      <div class="divide-y">
        <div class="flex">
          <div class="w-96 flex-none"></div>
          <div class="divide-x flex-grow divide-x flex">
            <div class="w-64 px-4 py-2">
              <div class="text-xs text-gray-400 uppercase">Order BY</div>
            </div>
            <div class="w-64 px-4 py-2">
              <div class="text-xs text-gray-400 uppercase">Deliver By</div>
            </div>
            <div class="w-64 px-4 py-2">
              <div class="text-xs text-gray-400 uppercase">Will run out</div>
            </div>
          </div>
        </div>
        <div
          :key="product.sku"
          v-for="product in value"
          class="flex items-center w-full"
        >
          <div class="w-96 flex-none">{{ product.name }}</div>
          <div clas="flex-grow">
            <stock-timeline-item :dates="product.dates" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { includes, filter, orderBy } from 'lodash/fp';
import utils from '@/utils';
import StockTimelineItem from './StockTimelineItem.vue';
import { addMonths, subDays } from 'date-fns';

export default {
  name: 'StockTimeline',
  components: { StockTimelineItem },
  data() {
    return {
      keyDates: { today: new Date(), latest: null }
    };
  },
  computed: {
    ...mapGetters({
      products: 'getProducts',
      stockStats: 'getCurrentStockStats'
    }),

    filteredProducts() {
      let products = this.products;

      products = filter(function(o) {
        return !o.discontinued;
      }, products);

      products = filter(function(o) {
        return !includes('taster', o.foodtype);
      }, products);

      return orderBy(['dates.po'], ['asc'], products);
    },
    groupedFood() {
      const vals = this.filteredProducts.filter(
        fp => !includes(fp.foodtype, ['tape', 'box'])
      );

      return { count: vals.length, data: utils.groupProducts(vals) };
    },
    timelineMonths() {
      return '';
    }
  },
  created() {
    if (!this.stockStats) {
      this.$store.dispatch('getStockStats').then(() => {
        this.products.forEach(p => {
          const stat = this.stockStats.find(ss => ss.sku === p.sku);
          const instock = stat.instock <= 0 ? 0 : stat.instock;
          let remaining = utils.round(instock / stat.active, 0);

          const rod = addMonths(this.keyDates.today, remaining);

          const dt = subDays(rod, p.etdtime);

          const po = subDays(dt, p.leadtime);

          p.dates = {
            roDate: !isNaN(rod) ? rod : null,
            delivery: !isNaN(dt) ? dt : null,
            po: !isNaN(po) ? po : null
          };
        });
      });
    }
  }
};
</script>

<style></style>
