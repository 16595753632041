<template>
  <div class="flex py-6  px-8 w-full">
    <transition name="fade" mode="out-in">
      <div v-if="product" class="flex flex-row w-full">
        <card class="text-center  sidebar flex-none border-r border-gray-200">
          <div class="pb-0">
            <div
              class="w-full h-80 rounded  overflow-hidden relative bg-gray-200  justify-between flex items-center flex-none bg-no-repeat bg-cover bg-center background-offset"
              :style="imageStyle"
            >
              <div
                class="w-full text-center relative -top-10"
                v-if="!showImage(product.sku)"
              >
                <font-awesome-icon
                  :icon="['fal', 'image-polaroid']"
                  class="text-6xl text-gray-300"
                />
              </div>

              <div
                class="absolute bottom-0 flex flex-col z-10 w-full h-72 justify-end  map-shadow"
              >
                <div class="flex justify-center py-2 mb-2">
                  <span
                    class="rounded p-1 px-2 text-xs font-semibold uppercase"
                    :class="getStatus(product.available)"
                    >{{
                      product.discontinued
                        ? 'Discontinued'
                        : product.available
                        ? 'Available'
                        : 'Unavailable'
                    }}</span
                  >
                </div>
                <div class="font-semibold text-lg">{{ product.name }}</div>

                <div class="text-gray-500 text-sm">{{ product.sku }}</div>
              </div>
            </div>
          </div>
          <div class="card-section" v-if="currentProductStats">
            <div class="flex flex-col mt-8 pb-4 ">
              <div class="font-semibold mb-2 text-left">Lead Times</div>
              <div class="flex justify-between items-center py-1">
                <div class="text-gray-600">PO</div>
                <div class="">{{ product.leadtime / 7 }} Weeks</div>
              </div>
              <div class="flex justify-between items-center py-1">
                <div class="text-gray-600">Delivery</div>
                <div class="">{{ product.etdtime / 7 }} Weeks</div>
              </div>
            </div>
            <div class="flex flex-col mt-8 pb-4 ">
              <div class="font-semibold mb-2 text-left">Stock</div>
              <div class="flex justify-between items-center py-1">
                <div class="text-gray-600">In Stock</div>
                <div class="">
                  {{ currentProductStats.instock | numberFormat }}
                </div>
              </div>
              <div class="flex justify-between items-center py-1">
                <div class="text-gray-600">In subscriptions</div>
                <div class="">
                  {{ formatValue(currentProductStats.active) }}
                </div>
              </div>
              <div class="flex justify-between items-center py-1">
                <div class="text-gray-600">Months of Stock</div>
                <div class="">{{ getStock() }}</div>
              </div>
            </div>
          </div>
        </card>
        <card class="flex-grow overflow-y-auto">
          <tabs>
            <tab key="Overview" slot="overview">
              <overview :product="product" />
            </tab>
            <tab key="Stock" slot="stock">
              <stock :product="product" />
            </tab>
            <tab key="Settings" slot="settings" v-if="isAdmin">
              <settings :product="product" />
            </tab>
          </tabs>
        </card>
      </div>
      <div v-else class="flex items-center justify-center flex-grow">
        no product found
      </div>
    </transition>
  </div>
</template>

<script>
import Card from '@/components/common/ui/Card';
import Tabs from '@/components/common/ui/tabs/Tabs';
import Tab from '@/components/common/ui/tabs/Tab';
import Stock from '@/components/common/ui/products/Stock';
import Overview from '@/components/common/ui/products/Overview';
import Settings from '@/components/common/ui/products/Settings';
import { includes, find } from 'lodash/fp';
import { mapGetters } from 'vuex';
export default {
  components: {
    Card,
    Stock,
    Overview,
    Settings,
    Tabs,
    Tab
  },

  computed: {
    imageStyle() {
      if (this.showImage(this.product.sku)) {
        return {
          'background-image': `url(/images/products/${this.getImage(
            this.product.sku
          )}.webp)`
        };
      }
      return '';
    },
    ...mapGetters({
      stockStats: 'getCurrentStockStats',
      isAdmin: 'isAdmin',
      product: 'getActiveProductGetter'
    }),
    currentProductStats() {
      return find(['sku', this.product.sku], this.stockStats) || null;
    }
  },
  methods: {
    getStatus(available) {
      if (available) {
        return 'text-green-500 bg-green-200';
      } else {
        return 'text-red-500 bg-red-200';
      }
    },

    formatValue(value) {
      if (this.product.foodtype === 'dry') {
        return value / 800;
      } else {
        return value.toLocaleString();
      }
    },
    getImage(sku) {
      if (includes('W_PCH', sku) && !includes('W_PCH_OCN')) {
        return 'W_PCH';
      }
      if (includes('D_BAG', sku)) {
        return 'D_BAG';
      }
      return sku;
    },
    showImage(sku) {
      if (includes('T_TST', sku)) {
        return false;
      }
      return true;
    },
    getStock() {
      return Math.floor(
        this.currentProductStats.instock / this.currentProductStats.active
      );
    }
  },
  mounted() {
    this.$store
      .dispatch('getProductProfile', { id: Number(this.$route.params.id) })
      .then(() => {
        if (!this.stockStats) {
          this.$store.dispatch('getStockStats');
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};
</script>

<style lang="scss" scoped>
.sidebar {
  width: 320px;
}

.background-offset {
  background-position: 0px -60px;
}
.map-shadow {
  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  background: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 78%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
}
</style>
